.App {
  text-align: center;
}

.main-bg {
  position: absolute;
  overflow: hidden;
  background-repeat: no-repeat;
  object-fit: cover;
  width: 100%;
  height: 100vh;
  left: 0;
}

.logo {
  position: relative;
  z-index: 1;
  animation: zoom-in-out 8s ease-in-out infinite;
  height: auto;
  max-width: 70%;
}

.logo-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 100px;
  z-index: 1;
  color: #fff;
}

@keyframes zoom-in-out {
  0% {
    scale: 1;
    -webkit-filter: drop-shadow(0px 0px 5px #5900e9);
    filter: drop-shadow(0px 0px 5px #5900e9);
  }

  50% {
    scale: 1.2;
    -webkit-filter: drop-shadow(0px 0px 5px #5900e9);
    filter: drop-shadow(0px 0px 50px #5900e9);
  }

  100% {
    scale: 1;
    -webkit-filter: drop-shadow(0px 0px 5px #5900e9);
    filter: drop-shadow(0px 0px 5px #5900e9);
  }
}
